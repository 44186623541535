import './App.css';
import Main from './component/Main';


function App() {
  return (
    <div className="">
        <Main/>
    </div>
  );
}

export default App;
